import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import { SVG } from '../../components/icons';
import Slider from '../../components/slider';
import { SM, XS } from '../../config/breakpoints';

import styles from './icon-slider.module.scss';

const IconSlider = ({ title, items, slidesToShow }) => {
	const [isClient, setIsClient] = useState(false);
	const isMinSM = useMediaQuery({ minWidth: SM });
	const isMinXS = useMediaQuery({ minWidth: XS });

	useEffect(() => {
		setIsClient(true);
	}, []);

	const slidesCount = isClient
		? isMinSM
			? slidesToShow + 2
			: isMinXS
			? slidesToShow + 1
			: slidesToShow
		: slidesToShow;

	return (
		Array.isArray(items) &&
		items.length > 0 && (
			<div className={styles.wrapper}>
				<h2 className={styles.title}>{title}</h2>
				<Slider
					slidesToShow={slidesCount}
					slidesToScroll={slidesCount}
					centerPadding={'100px'}>
					{items.map(({ uuid, icon: { publicURL: src } }) => (
						<div key={uuid} className={styles.item}>
							<SVG path={src} className={styles.svg} />
						</div>
					))}
				</Slider>
			</div>
		)
	);
};

IconSlider.defaultProps = {
	slidesToShow: 2,
	title: null,
	items: []
};

IconSlider.propTypes = {
	title: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			uuid: PropTypes.string.isRequired,
			icon: PropTypes.shape({
				publicURL: PropTypes.string.isRequired
			}).isRequired
		})
	),
	slidesToShow: PropTypes.number
};

export default IconSlider;
